// src/components/CheckListComponent.js
import React from 'react';
import './CheckListComponent.css'; // Import the CSS styles

const CheckListComponent = ({
  items,
  marginLeft,
  marginBottom,
  fontSize = 'medium',
  imageSize = 35
}) => {
  const getImageSource = (item) => {
    if (item.moveoutService && !item.deepService && !item.standardService) {
      return '/images/icons/checklist-checked-moveout.png';
    } else if (item.deepService && !item.standardService) {
      return '/images/icons/checklist-checked-deep.png';
    } else if (item.standardService) {
      return '/images/icons/checklist-checked.png';
    } else {
      return '/images/icons/checklist-unchecked.png';
    }
  };

  // console.log("items", items);

  const getDescription = (item) => {
    const roomType = item.roomType;
    const specificDescription = item[`${roomType}Description`];
    return specificDescription || item.generalDescription;
  };

  return (
    <div className="list-container" style={{ marginLeft, marginBottom }}>
      {items.map((item, index) => (
        <div className="list-item-container" key={item.id || index}>
          <div className="image-container">
            <img src={getImageSource(item)} alt="Checklist icon" style={{ width: imageSize, height: imageSize }} />
          </div>
          <div className="text-container">
            <span className={`text ${fontSize}`}>{getDescription(item)}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CheckListComponent;
