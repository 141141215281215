import React, { useState, useEffect } from 'react';
import './styles.css';
import axios from 'axios';
import { apiBaseUrl } from '../apiConfig';
import Checkbox from '../components/Checkbox';
import ErrorBox from '../components/ErrorBox';
import PriceEstimateDisplay from '../components/PriceEstimateDisplay';
import CheckListComponent from '../components/CheckListComponent';
import SectionHeader from '../components/SectionHeader';
import NumberIncrementor from '../components/NumberIncrementor';

import Header from '../components/Header'; 

function HomeScreen() {
  // (Move the entire content of your existing App.js here)
  
  const [squareFeet, setSquareFeet] = useState('');
  const [cleaningType, setCleaningType] = useState('');
  const [priceDetails, setPriceDetails] = useState({ priceTitle: "Estimated Price", price: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [priceError, setPriceError] = useState([]);  
  const [checklistItems, setChecklistItems] = useState([]); 

  const [numberBathrooms, setNumberBathrooms] = useState(1);

  const handleIncrementBathrooms = () => setNumberBathrooms(current => current + 1);
  const handleDecrementBathrooms = () => setNumberBathrooms(current => Math.max(1, current - 1));


  useEffect(() => {
    const fetchChecklistItems = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/get-checklist-items`);
        if (response.data && Array.isArray(response.data.checkListItems)) {
          setChecklistItems(response.data.checkListItems); // Access the array from the object
          console.log(response.data.checkListItems); // Logging the fetched data
        } else {
          setChecklistItems([]); // Ensure it's always an array even if data is missing
          console.error("Expected an array for checklist items, received:", response.data);
        }
      } catch (error) {
        console.error('Failed to fetch checklist items:', error);
        // if want to use, need a dedicated useState
        // setPriceError(prevErrors => [...prevErrors, 'Failed to fetch checklist items']);
      }
    };
  
    fetchChecklistItems();
  }, []);  


  const handleSquareFeetChange = (event) => {
    const value = event.target.value;
    // Allow only numbers that do not start with zero
    if (/^[1-9]\d*$/.test(value) || value === '') {
      setSquareFeet(value);
    }
  };  


  const getGeneralChecklist = (serviceType) => {
    // Assuming allChecklistItems is now a flat array containing all items
    const roomItems = checklistItems || [];

    console.log("roomItems", roomItems);
  
    return roomItems
      .filter(item => item.roomType === 'general' && item[`${serviceType}Service`] === true)
      .sort((a, b) => a.generalOrder - b.generalOrder);
  };


  const getRoomTypeChecklist = (roomType, serviceType) => {
    const roomItems = checklistItems || [];

    console.log("roomType", roomType);
    console.log("roomItems", roomItems);
  
    return roomItems
      .filter(item => item.roomType === roomType && item[`${serviceType}Service`] === true)
      .sort((a, b) => a[`${roomType}Order`] - b[`${roomType}Order`]);
  };  


  const handleCleaningTypeChange = (type) => {
    setCleaningType(type);  // Update the state
    if (parseInt(squareFeet, 10) >= 500) {
      fetchPriceEstimate(type);  // Fetch new estimate if conditions are met
    } else {
      // Reset the states only if the square footage is below the threshold
      setSquareFeet('');
      setPriceDetails({ priceTitle: "Estimated Price", price: 0 });
      setPriceError([]);
    }
  };


  const validateInputs = (squareFeet, cleaningType) => {
    const errors = [];
    if (!squareFeet || parseInt(squareFeet, 10) < 500) {
      errors.push("Please enter a square footage estimate of at least 500.");
    }
    if (!cleaningType) {
      errors.push("Please select a cleaning type.");
    }
    return errors;
  };
    
  
  // Helper function to round the price to the nearest 5 dollars
  const roundToNearestFive = (num) => {
    return Math.round(num / 5) * 5;
  };


  // Frontend fetchPriceEstimate function
  const fetchPriceEstimate = async (type) => {
    const currentCleaningType = type || cleaningType; // Use provided type or fall back to the state

    const errors = validateInputs(squareFeet, currentCleaningType);
    if (errors.length > 0) {
      setPriceError(errors);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setPriceError([]);
    try {
      const response = await axios.get(`${apiBaseUrl}/quick-price-estimate`, {
        params: { squareFeet, cleaningType: currentCleaningType, numberBathrooms } // Changed here
      });
      if (response.data && response.status === 200) {
        const roundedPrice = roundToNearestFive(parseFloat(response.data.regularEstimate)); // Ensure conversion to float
        setPriceDetails({
          priceTitle: "Estimated Price With Tax",
          price: roundedPrice
        });
      } else {
        throw new Error('Failed to fetch estimate');
      }
    } catch (err) {
      console.error('API Error:', err);
      setPriceError(['Failed to fetch estimate. Please try again.']);
    }
    setIsLoading(false);
  };

  

  const standardBedroomItems = getRoomTypeChecklist('bedroom', "standard");
  const deepBedroomItems = getRoomTypeChecklist('bedroom', "deep");


  return (
    <div className="full-width-container">

      {/* <header className="content-container">
        <img src="images/logo.png" alt="Sparkle" className="header-logo" />
      </header> */}

      <Header />


      <main className="content-container">
        <div className="main-feature">
          <div className="feature-image">
            <img src="images/iphones-home-cropped.png" alt="Sparkle App Screenshot" />
          </div>
          <div className="feature-content">
            <h1 className="feature-title">Welcome to Sparkle!</h1>
            <small className="feature-subtitle">Professional House Cleaning</small>
            <p className="location-info">Based in Greenville, SC!</p>
            <a href="https://apps.apple.com/us/app/sparkle-cleaning/id6474100183" target="_blank" rel="noopener noreferrer">
              <img src="images/appstore.png" alt="Download on the App Store" style={{ height: '75px' }} />
            </a>
          </div>
        </div>
      </main>


      <section className="full-width-section">
        <div className="content-container">
          <div className="grid-section">
            <div className="text-column">
              <h2 className="section-title">Best Cleaners</h2>
              <p className="section-paragraph">Sparkle only hires outstanding people. Every cleaner undergoes a comprehensive background check and is fully bonded. Sparkle goes out of its way to make the job work for its cleaners so they can go out of their way to work for you.</p>
            </div>
            <div className="image-column">
              <img src="images/iphone-property.png" alt="Property Details" />
            </div>
            <div className="text-column">
              <h2 className="section-title">Centralized App Management</h2>
              <p className="section-paragraph">Get instant estimates, schedule through the app on the live calendar, manage multiple properties easily, and generate custom photo to do lists for cleaners to match an after photo to in order to complete your job as you wish.</p>
            </div>
          </div>
        </div>
      </section>


      <section className="estimate-container">
        <div className="estimate-section">
          <h2>Quick Price Estimate</h2>
          <div>
            <Checkbox
              label="Standard Clean"
              checked={cleaningType === 'standard'}
              onChange={() => handleCleaningTypeChange('standard')}
            />
            <Checkbox
              label="Deep Clean"
              checked={cleaningType === 'deep'}
              onChange={() => handleCleaningTypeChange('deep')}
            />
          </div>

          <input
            type="number"
            placeholder="Square Footage Estimate"
            value={squareFeet}
            onChange={handleSquareFeetChange}
            min="500"
            inputMode="numeric"
            pattern="[0-9]*"
            className="square-footage-input"
          />

          <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>Number of Bathrooms:</h3>


          <NumberIncrementor
            value={numberBathrooms}
            onIncrement={handleIncrementBathrooms}
            onDecrement={handleDecrementBathrooms}
          />


          <button 
            className="get-estimate-button"
            onClick={() => fetchPriceEstimate(cleaningType)} // Pass current cleaningType explicitly
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Get Estimate'}
          </button>
          <PriceEstimateDisplay priceDetails={priceDetails} />
          <ErrorBox errorTexts={priceError} />          
        </div>
      </section>


      <section className="content-container checklist-grid-container">
        <div className={`checklist-column ${cleaningType === "standard" ? "selected-type" : ""}`}>
          <SectionHeader
            text="Standard Clean Checklist"
            backgroundColor="#000000"
            fontColor="#FFFFFF"
            textAlign="center"
            fontSize="large"
          />
          <div className="checklist-section">
            <h2>All Rooms - Standard</h2>
            <CheckListComponent
              items={getGeneralChecklist("standard")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
          <div className="checklist-section">
            <h2>Bathrooms - Standard</h2>
            <CheckListComponent
              items={getRoomTypeChecklist('bathroom', "standard")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
          {standardBedroomItems.length > 0 && (
            <div className="checklist-section">
              <h2>Bedrooms - Standard</h2>
              <CheckListComponent
                items={standardBedroomItems}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
          )}
          <div className="checklist-section">
            <h2>Kitchen - Standard</h2>
            <CheckListComponent
              items={getRoomTypeChecklist('kitchen', "standard")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
        </div>

        <div className={`checklist-column ${cleaningType === "deep" ? "selected-type" : ""}`}>
         <SectionHeader
            text="Deep Clean Checklist"
            backgroundColor="#000000"
            fontColor="#FFFFFF"
            textAlign="center"
            fontSize="large"
          />
          <div className="checklist-section">
            <h2>All Rooms - Deep</h2>
            <CheckListComponent
              items={getGeneralChecklist("deep")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
          <div className="checklist-section">
            <h2>Bathrooms - Deep</h2>
            <CheckListComponent
              items={getRoomTypeChecklist('bathroom', "deep")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
          {deepBedroomItems.length > 0 && (
            <div className="checklist-section">
              <h2>Bedrooms - Deep</h2>
              <CheckListComponent
                items={deepBedroomItems}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
          )}
          <div className="checklist-section">
            <h2>Kitchen - Deep</h2>
            <CheckListComponent
              items={getRoomTypeChecklist('kitchen', "deep")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
        </div>
      </section>


      <section className="content-container contact-section">
        <div className="contact-details">
          <div className="contact-items-wrapper">  
            <div className="contact-item">
              <img src="images/icon-message.png" alt="Email" />
              <div className="link-container">
                <a href="mailto:contact@sparkleservicesusa.com">contact@sparkleservicesusa.com</a>
              </div>
            </div>
            <div className="contact-item">
              <img src="images/icon-phone.png" alt="Phone" />
              <div className="link-container">
                <a href="tel:(864) 616-5884">(864) 616-5884</a>
              </div>
            </div>
          </div>
        </div>
        <div className="social-links">
          <a href="https://www.facebook.com/SparkleCleaningSC/" target="_blank" rel="noopener noreferrer">
            <img src="images/facebook.png" alt="Facebook" />
          </a>
        </div>
        <div className="footer-text">
          <p>Copyright 2024 Sparkle Services USA LLC</p>
        </div>
      </section>


    </div>
  );












}

export default HomeScreen;
