// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { setUser, clearUser } from './redux/authSlice'; // Import actions from authSlice
import HomeScreen from './screens/HomeScreen';
import MarketingScreen from './screens/MarketingScreen';
import EmailLoginScreen from './screens/EmailLoginScreen';
import { auth } from './firebaseConfig';

function App() {
  const dispatch = useDispatch(); // Set up the Redux dispatch function
  const user = useSelector((state) => state.auth.user); // Access the user from Redux
  const [isLoading, setIsLoading] = useState(true); // Add a loading state to handle async

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        dispatch(setUser(currentUser)); // Dispatch the setUser action when user is logged in
      } else {
        dispatch(clearUser()); // Clear user when logged out
      }
      setIsLoading(false); // Stop loading once user state is determined
    });

    return () => unsubscribe(); // Cleanup subscription
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>; // Optionally show a loading indicator while checking auth state
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route
          path="/marketing"
          element={user ? <MarketingScreen /> : <Navigate to="/login" />} // Protect marketing route
        />
        <Route
          path="/login"
          element={user ? <Navigate to="/marketing" /> : <EmailLoginScreen />} // Redirect to marketing if logged in
        />
      </Routes>
    </Router>
  );
}

export default App;










// // src/App.js
// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { onAuthStateChanged } from 'firebase/auth';
// import HomeScreen from './screens/HomeScreen';
// import MarketingScreen from './screens/MarketingScreen';
// import EmailLoginScreen from './screens/EmailLoginScreen';  // Using Email Login screen
// import { auth } from './firebaseConfig';

// function App() {
//   const [user, setUser] = useState(null);
//   const [isLoading, setIsLoading] = useState(true); // Add a loading state to handle async

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       setUser(currentUser);
//       setIsLoading(false); // Stop loading once user state is determined
//     });

//     return () => unsubscribe(); // Cleanup subscription
//   }, []);

//   if (isLoading) {
//     return <div>Loading...</div>; // Optionally show a loading indicator while checking auth state
//   }

//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<HomeScreen />} />
//         <Route
//           path="/marketing"
//           element={user ? <MarketingScreen /> : <Navigate to="/login" />} // Protect marketing route
//         />
//         <Route
//           path="/login"
//           element={user ? <Navigate to="/marketing" /> : <EmailLoginScreen />} // Redirect to marketing if logged in
//         />
//       </Routes>
//     </Router>
//   );
// }

// export default App;
