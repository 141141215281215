import React from 'react';
import './ErrorBox.css'; // Ensure this file is correctly linked

const ErrorBox = ({ errorTexts }) => {
  if (!errorTexts || errorTexts.length === 0) return null;

  return (
    <div className="error-box">
      <ul> {/* Use an unordered list to list errors */}
        {errorTexts.map((text, index) => (
          <li key={index} className="error-message"> {/* Use list items for each error */}
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorBox;
