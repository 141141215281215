import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Header from '../components/Header';
import GoogleMapComponent from '../components/GoogleMapComponent';
import PhoneInputComponent from '../components/PhoneInputComponent'; // Import the new PhoneInputComponent
import { apiBaseUrl } from '../apiConfig';
import './MarketingScreen.css';

import Checkbox from '../components/Checkbox';
import ErrorBox from '../components/ErrorBox';
import PriceEstimateDisplay from '../components/PriceEstimateDisplay';
import CheckListComponent from '../components/CheckListComponent';
import SectionHeader from '../components/SectionHeader';
import NumberIncrementor from '../components/NumberIncrementor';


import CustomButton from '../components/CustomButton';



function MarketingScreen() {
  const user = useSelector((state) => state.auth.user);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState('');
  const [addressDetails, setAddressDetails] = useState({});
  const [location, setLocation] = useState({});
  
  // New state for customer details
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState(''); // Will store the raw phone number
  const [email, setEmail] = useState('');

  // New state for the "Notes" section
  const [notes, setNotes] = useState('');

  //checklist and estimates
  const [squareFeet, setSquareFeet] = useState('');
  const [cleaningType, setCleaningType] = useState('');
  const [priceDetails, setPriceDetails] = useState({ priceTitle: "Estimated Price", price: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [priceError, setPriceError] = useState([]);  
  const [checklistItems, setChecklistItems] = useState([]); 

  // Loading state for save button
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const [numberBathrooms, setNumberBathrooms] = useState(1);

  //property values
  const [placeId, setPlaceId] = useState('');  // Store place ID
  const [longitude, setLongitude] = useState('');  // Store longitude
  const [latitude, setLatitude] = useState('');  // Store latitude
  const [formattedAddress, setFormattedAddress] = useState('');  // Store formatted address
  const [address, setAddress] = useState('');  // Store street address
  const [city, setCity] = useState('');  // Store city
  const [state, setState] = useState('');  // Store state
  const [zipCode, setZipCode] = useState('');  // Store zip code


  // New state for property type
  const [propertyType, setPropertyType] = useState('house'); // Default to 'house'
  const [apartmentName, setApartmentName] = useState(''); // Only for apartments
  const [apartmentUnit, setApartmentUnit] = useState(''); // Only for apartments

  // New state for general interest checkbox
  const [interest, setInterest] = useState(false);

  const inputRef = useRef(null); // Create a reference to the input field


  // Function to handle house selection
  const handleHouseSelection = () => {
    setPropertyType('house');
    setApartmentName(''); // Reset apartment name when switching to house
    setApartmentUnit(''); // Reset apartment unit when switching to house
  };

  const handleIncrementBathrooms = () => setNumberBathrooms(current => current + 1);
  const handleDecrementBathrooms = () => setNumberBathrooms(current => Math.max(1, current - 1));

  const resetForm = () => {
    setError('');
    setAddressDetails({});
    setLocation({});
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail(''); 
    setNotes('');
    setSquareFeet('');
    setCleaningType('');
    setPriceDetails({ priceTitle: "Estimated Price", price: 0 });
    setPriceError([]);
    setNumberBathrooms(1);
    setPlaceId('');
    setLongitude('');
    setLatitude('');
    setFormattedAddress('');
    setAddress('');
    setCity('');
    setState('');
    setZipCode('');
    setPropertyType('house');
    setApartmentName('');
    setApartmentUnit('');
    setInterest(false);
  };


  // Function to handle the form clear with confirmation
  const handleClearForm = () => {
    const isConfirmed = window.confirm('Are you sure you want to clear the form? This action cannot be undone.');
    if (isConfirmed) {
      resetForm(); // Call the resetForm function if the user confirms
    }
  };


  const handleInputChange = async (text) => {
    setInputValue(text);

    if (!text.trim()) {
      setSuggestions([]);
      return;
    }

    try {
      const token = await user.getIdToken();
      const response = await axios.post(
        `${apiBaseUrl}/property/get-address-suggestions`,
        { input: text },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setSuggestions(response.data.suggestions || []);
        setError('');
      } else {
        setError('Failed to fetch suggestions.');
      }
    } catch (err) {
      console.error('Error during API call:', err);
      setError('Failed to call API. Please try again.');
    }
  };


  // Helper function to format the phone number into (XXX) - XXX - XXXX format
  const formatPhoneForUI = (phoneNumber) => {
    let digits = phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters
    if (digits.length === 11 && digits.startsWith('1')) {
      digits = digits.slice(1); // Remove the leading '1' for US phone numbers
    }
    if (digits.length === 10) {
      return `(${digits.slice(0, 3)}) - ${digits.slice(3, 6)} - ${digits.slice(6)}`;
    }
    return phoneNumber; // Return original if it's not a valid 10-digit number
  };



  // Suggestion click handler
  const handleSuggestionClick = async (suggestion) => {
    // Reset the form before loading new place details
    resetForm();

    setInputValue(suggestion.description);
    setSuggestions([]);

    try {
      const token = await user.getIdToken();
      const response = await axios.post(
        `${apiBaseUrl}/property/web-get-place-details`,
        { placeId: suggestion.place_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        const { addressDetails, location, savedData } = response.data;

        // Set the address components from the API response
        setPlaceId(addressDetails.placeId);
        setLongitude(location.longitude);
        setLatitude(location.latitude);
        setFormattedAddress(addressDetails.formattedAddress);
        setAddress(addressDetails.address);
        setCity(addressDetails.city);
        setState(addressDetails.state);
        setZipCode(addressDetails.zip);

        // Set address and map details
        setAddressDetails(addressDetails);
        setLocation(location);

        // If saved data is available, populate the relevant fields
        if (savedData) {
          setFirstName(savedData.firstName || '');
          setLastName(savedData.lastName || '');
          setEmail(savedData.email || '');
          setNotes(savedData.notes || '');
          setSquareFeet(savedData.squareFeet || '');
          setNumberBathrooms(savedData.numberBathrooms || 1);
          setPropertyType(savedData.propertyType || 'house');
          setApartmentName(savedData.apartmentName || '');
          setApartmentUnit(savedData.apartmentUnit || '');
          setInterest(savedData.customerInterestBinary || false);

          // Set the cleaning type if it is 'standard' or 'deep'
          if (['standard', 'deep'].includes(savedData.cleaningType)) {
            setCleaningType(savedData.cleaningType);
          } else {
            setCleaningType(''); // Default or reset if no valid cleaning type
          }

          // Format the phone number for UI if it exists and is valid
          if (savedData.phone) {
            const formattedPhone = formatPhoneForUI(savedData.phone);
            setPhone(formattedPhone); 
          }
        }
      } else {
        setError('Failed to fetch place details.');
      }
    } catch (err) {
      console.error('Error during API call:', err);
      setError('Failed to call API. Please try again.');
    }
  };


  // estimate / checklist price calls
  useEffect(() => {
    const fetchChecklistItems = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/get-checklist-items`);
        if (response.data && Array.isArray(response.data.checkListItems)) {
          setChecklistItems(response.data.checkListItems); // Access the array from the object
          console.log(response.data.checkListItems); // Logging the fetched data
        } else {
          setChecklistItems([]); // Ensure it's always an array even if data is missing
          console.error("Expected an array for checklist items, received:", response.data);
        }
      } catch (error) {
        console.error('Failed to fetch checklist items:', error);
        // if want to use, need a dedicated useState
        // setPriceError(prevErrors => [...prevErrors, 'Failed to fetch checklist items']);
      }
    };
  
    fetchChecklistItems();
  }, []);  


  // Save function
  const handleSave = async () => {
    setSaveIsLoading(true);

    const prospectData = {
      firstName,
      lastName,
      phone,
      email,
      notes,
      placeId,
      longitude,
      latitude,
      formattedAddress,
      address,
      city,
      state,
      zipCode,
      squareFeet,
      numberBathrooms,
      propertyType,
      apartmentName,
      apartmentUnit,
      interest,
      cleaningType, // Include cleaning type from the state
      priceEstimate: priceDetails.price // Include the price estimate from priceDetails
    };

    try {
      const token = await user.getIdToken(); // Assuming you are using Firebase auth

      const response = await axios.post(
        `${apiBaseUrl}/property/save-marketing-prospect`,
        prospectData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        alert('Saved successfully');
      } else {
        alert('Failed to save');
      }
    } catch (error) {
      console.error('Error saving prospect:', error);
      alert('An error occurred while saving.');
    } finally {
      setSaveIsLoading(false);
    }
  };



  const handleSquareFeetChange = (event) => {
    const value = event.target.value;
    // Allow only numbers that do not start with zero
    if (/^[1-9]\d*$/.test(value) || value === '') {
      setSquareFeet(value);
    }
  };  


  const getGeneralChecklist = (serviceType) => {
    // Assuming allChecklistItems is now a flat array containing all items
    const roomItems = checklistItems || [];

    return roomItems
      .filter(item => item.roomType === 'general' && item[`${serviceType}Service`] === true)
      .sort((a, b) => a.generalOrder - b.generalOrder);
  };


  const getRoomTypeChecklist = (roomType, serviceType) => {
    const roomItems = checklistItems || [];
  
    return roomItems
      .filter(item => item.roomType === roomType && item[`${serviceType}Service`] === true)
      .sort((a, b) => a[`${roomType}Order`] - b[`${roomType}Order`]);
  };  


  const handleCleaningTypeChange = (type) => {
    setCleaningType(type);  // Update the state
    if (parseInt(squareFeet, 10) >= 500) {
      fetchPriceEstimate(type);  // Fetch new estimate if conditions are met
    } else {
      // Reset the states only if the square footage is below the threshold
      setSquareFeet('');
      setPriceDetails({ priceTitle: "Estimated Price", price: 0 });
      setPriceError([]);
    }
  };


  const validateInputs = (squareFeet, cleaningType) => {
    const errors = [];
    if (!squareFeet || parseInt(squareFeet, 10) < 500) {
      errors.push("Please enter a square footage estimate of at least 500.");
    }
    if (!cleaningType) {
      errors.push("Please select a cleaning type.");
    }
    return errors;
  };
  

    
  // Helper function to round the price to the nearest 5 dollars
  const roundToNearestFive = (num) => {
    return Math.round(num / 5) * 5;
  };


  const fetchPriceEstimate = async (type) => {
    const currentCleaningType = type || cleaningType; // Use provided type or fall back to the state
  
    const errors = validateInputs(squareFeet, currentCleaningType);

    if (errors.length > 0) {
      setPriceError(errors);
      setIsLoading(false);
      return;
    }
  
    setIsLoading(true);
    setPriceError([]);
    try {
      const response = await axios.get(`${apiBaseUrl}/quick-price-estimate`, {
        params: { squareFeet, cleaningType: currentCleaningType, numberBathrooms }
      });
      if (response.data && response.status === 200) {
        const roundedPrice = roundToNearestFive(parseFloat(response.data.regularEstimate)); // Ensure conversion to float
        setPriceDetails({
          priceTitle: "Estimated Price With Tax",
          price: roundedPrice
        });
      } else {
        throw new Error('Failed to fetch estimate');
      }
    } catch (err) {
      console.error('API Error:', err);
      setPriceError(['Failed to fetch estimate. Please try again.']);
    }
    setIsLoading(false);
  };    

  const standardBedroomItems = getRoomTypeChecklist('bedroom', "standard");
  const deepBedroomItems = getRoomTypeChecklist('bedroom', "deep");


  const clearInputAndSuggestions = () => {
    setInputValue('');    // Reset input value
    setSuggestions([]);    // Clear suggestions
    inputRef.current.focus(); // Focus the input field after clearing
  };
  

  return (
    <div className="full-width-container">
      <Header />

      <div className="content-container column-layout">
        <h2>Customer Details</h2>
        <section className="content-container customer-details-grid"> 

          {/* Left column for First and Last Name */}
          <div className="customer-column">
            <div className="details-row">
              <label>First Name:</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter first name"
                className="customer-input"
              />
            </div>
            <div className="details-row">
              <label>Last Name:</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter last name"
                className="customer-input"
              />
            </div>
          </div>

          {/* Right column for Phone and Email */}
          <div className="customer-column">
            <div className="details-row">
              <label>Phone:</label>
              <PhoneInputComponent phone={phone} setPhone={setPhone} />
            </div>
            <div className="details-row">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                className="customer-input"
              />
            </div>
          </div>

          {/* Notes section spanning both columns */}
          <div className="notes-row">
            <div className="details-row">
              <label>Notes:</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Enter any notes"
                className="notes-textarea"
              />
            </div>
          </div>

          {/* General interest checkbox section */}
          <div className="interest-row">
              <Checkbox
                checked={interest}
                onChange={setInterest}
                label="General Interest"
              />
          </div>

          {/* Save Button (only visible if placeId is not an empty string) */}
          <div className="details-row">
            {placeId && (
              <CustomButton
                color="green"
                text="Save"
                onClick={handleSave}
                disabled={saveIsLoading}
                isLoading={saveIsLoading}
                loadingText="Saving..."
              />
            )}
            <CustomButton
              color="#ff3333"
              text="Clear All"
              onClick={handleClearForm} 
              disabled={saveIsLoading} 
              isLoading={false} 
              loadingText=""
            />
          </div>


        </section>
      </div>



      <section className="estimate-container">
        <div className="estimate-section">
          <h2>Quick Price Estimate</h2>
          <div>
            <Checkbox
              label="Standard Clean"
              checked={cleaningType === 'standard'}
              onChange={() => handleCleaningTypeChange('standard')}
            />
            <Checkbox
              label="Deep Clean"
              checked={cleaningType === 'deep'}
              onChange={() => handleCleaningTypeChange('deep')}
            />
          </div>

          <input
            type="number"
            placeholder="Square Footage Estimate"
            value={squareFeet}
            onChange={handleSquareFeetChange}
            min="500"
            inputMode="numeric"
            pattern="[0-9]*"
            className="square-footage-input"
          />

          <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>Number of Bathrooms:</h3>


          <NumberIncrementor
            value={numberBathrooms}
            onIncrement={handleIncrementBathrooms}
            onDecrement={handleDecrementBathrooms}
          />


          <button 
            className="get-estimate-button"
            onClick={() => fetchPriceEstimate(cleaningType)} // Pass current cleaningType explicitly
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Get Estimate'}
          </button>
          <PriceEstimateDisplay priceDetails={priceDetails} />
          <ErrorBox errorTexts={priceError} />          
        </div>
      </section>


      <section className="content-container checklist-grid-container">
        <div className={`checklist-column ${cleaningType === "standard" ? "selected-type" : ""}`}>
          <SectionHeader
            text="Standard Clean Checklist"
            backgroundColor="#000000"
            fontColor="#FFFFFF"
            textAlign="center"
            fontSize="large"
          />
          <div className="checklist-section">
            <h2>All Rooms - Standard</h2>
            <CheckListComponent
              items={getGeneralChecklist("standard")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
          <div className="checklist-section">
            <h2>Bathrooms - Standard</h2>
            <CheckListComponent
              items={getRoomTypeChecklist('bathroom', "standard")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
          {standardBedroomItems.length > 0 && (
            <div className="checklist-section">
              <h2>Bedrooms - Standard</h2>
              <CheckListComponent
                items={standardBedroomItems}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
          )}
          <div className="checklist-section">
            <h2>Kitchen - Standard</h2>
            <CheckListComponent
              items={getRoomTypeChecklist('kitchen', "standard")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
        </div>

        <div className={`checklist-column ${cleaningType === "deep" ? "selected-type" : ""}`}>
         <SectionHeader
            text="Deep Clean Checklist"
            backgroundColor="#000000"
            fontColor="#FFFFFF"
            textAlign="center"
            fontSize="large"
          />
          <div className="checklist-section">
            <h2>All Rooms - Deep</h2>
            <CheckListComponent
              items={getGeneralChecklist("deep")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
          <div className="checklist-section">
            <h2>Bathrooms - Deep</h2>
            <CheckListComponent
              items={getRoomTypeChecklist('bathroom', "deep")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
          {deepBedroomItems.length > 0 && (
            <div className="checklist-section">
              <h2>Bedrooms - Deep</h2>
              <CheckListComponent
                items={deepBedroomItems}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
          )}
          <div className="checklist-section">
            <h2>Kitchen - Deep</h2>
            <CheckListComponent
              items={getRoomTypeChecklist('kitchen', "deep")}
              marginLeft={15}
              marginBottom={15}
            />
          </div>
        </div>
      </section>

      {/* Property Type Section */}
      <div className="content-container column">
        <h2>Property Type</h2>
        <div className="details-row">
          <Checkbox
            checked={propertyType === 'house'}
            onChange={handleHouseSelection}  
            label="House"
          />
          <Checkbox
            checked={propertyType === 'apartment'}
            onChange={() => setPropertyType('apartment')}
            label="Apartment"
          />
        </div>

        {/* If 'Apartment' is selected, show these fields */}
        {propertyType === 'apartment' && (
          <div className="apartment-details">
            <div className="details-row">
              <label>Apartment Name:</label>
              <input
                type="text"
                value={apartmentName}
                onChange={(e) => setApartmentName(e.target.value)}
                placeholder="Enter apartment name"
                className="customer-input"
              />
            </div>
            <div className="details-row">
              <label>Apartment Unit:</label>
              <input
                type="text"
                value={apartmentUnit}
                onChange={(e) => setApartmentUnit(e.target.value)}
                placeholder="Enter apartment unit"
                className="customer-input"
              />
            </div>
          </div>
        )}
      </div>


      {/* Property Lookup Section */}
      <div className="content-container column-layout">
        <h2>Property Look Up</h2>

        <div className="input-and-button-container">
          <div className="input-container">
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder="Enter an address"
              className="address-input"
            />
          </div>

          <div className="button-container">
            <CustomButton
              text="Clear Address"
              color="#33cc33"  
              onClick={clearInputAndSuggestions}  // Calls the helper function
            />
          </div>
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>}

        <section className="suggestions-container">
          {suggestions.length > 0 &&
            suggestions.map((suggestion, index) => (
              <div
                key={index}
                className="suggestion-item"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.description}
              </div>
            ))}
        </section>
      </div>


      <main className="content-container">
        <section className="main-feature">
          <div className="address-details">
            <h2>Property Details</h2>
            <div className="details-row">
              <label>Street Address:</label>
              <p>{addressDetails.address || 'N/A'}</p>
            </div>
            <div className="details-row">
              <label>City:</label>
              <p>{addressDetails.city || 'N/A'}</p>
            </div>
            <div className="details-row">
              <label>State:</label>
              <p>{addressDetails.state || 'N/A'}</p>
            </div>
            <div className="details-row">
              <label>Zip Code:</label>
              <p>{addressDetails.zip || 'N/A'}</p>
            </div>
          </div>

          <div className="map-container">
            <h2>Map View</h2>
            {location.latitude && location.longitude ? (
              <GoogleMapComponent location={location} />
            ) : (
              <p>No map data available</p>
            )}
          </div>
        </section>
      </main>



      
    </div>
  );
}

export default MarketingScreen;
