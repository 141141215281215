import React from 'react';
import './Checkbox.css'; // Import the CSS styles

// // Import the images (make sure the paths are correct based on your public folder)
// import checkedImage from '../public/images/icons/checked.png';
// import uncheckedImage from '../public/images/icons/unchecked.png';

// Remove the imports and directly use the paths in the image src
const checkedImage = '/images/icons/checked.png';
const uncheckedImage = '/images/icons/unchecked.png';


const Checkbox = ({ checked, onChange, label, marginBottom }) => {
  const imageSource = checked ? checkedImage : uncheckedImage;

  // Conditional onClick handling
  const handleClick = () => {
    if (onChange) {
      onChange(!checked); // toggle checked state
    }
  };

  // Dynamically set the container style to include marginBottom if provided
  const containerStyle = { marginBottom: marginBottom || 0 };

  return (
    <div className="checkbox-container" style={containerStyle}>
      <button onClick={handleClick} className="checkbox-button">
        <img src={imageSource} alt="" className="checkbox-image" />
      </button>
      <div className="checkbox-label">
        <span>{label}</span>
      </div>
    </div>
  );
};

export default Checkbox;
