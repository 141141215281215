// src/components/NumberIncrementor.js
import React from 'react';
import './NumberIncrementor.css'; // Import CSS for styling

const NumberIncrementor = ({ value, onIncrement, onDecrement }) => {
  // Ensure that the value is not null before converting to string
  const displayValue = value === null ? '0' : value.toString();

  return (
    <div className="number-incrementor-container">
      <button className="button" onClick={onDecrement}>
        -
      </button>
      <span className="value-text">{displayValue}</span>
      <button className="button" onClick={onIncrement}>
        +
      </button>
    </div>
  );
};

export default NumberIncrementor;
