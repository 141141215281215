import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../redux/authSlice'; // Action to clear the user
import { auth } from '../firebaseConfig'; // Firebase auth
import './Header.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom'; // Import Link for navigation

function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user); // Get user from Redux

  const handleLogout = () => {
    auth.signOut().then(() => {
      dispatch(clearUser()); // Clear user in Redux
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  };

  return (
    <header className="content-container header">
      {/* Wrap the logo with a Link component that redirects to the home screen */}
      <Link to="/">
        <img src="images/logo.png" alt="Sparkle" className="header-logo" />
      </Link>
      {/* Only display the user status if logged in */}
      {user && (
        <div className="header-user-status">
          <span>{user.email}</span> {/* Display user's email */}
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}
    </header>
  );
}

export default Header;
