// src/apiConfig.js
const apiConfig = {
  development: 'http://localhost:3001',
  production: 'https://sparkle-services.ue.r.appspot.com'
};

// Manually switch environment here
// Uncomment the environment you want to use and comment out the other
// export const apiBaseUrl = apiConfig.development;
export const apiBaseUrl = apiConfig.production;
