import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDjVARCYQNdHvEwZH9rLxohTT7SMjzpOWw",
  authDomain: "sparkle-services.firebaseapp.com",
  projectId: "sparkle-services",
  storageBucket: "sparkle-services.appspot.com",
  messagingSenderId: "288293261999",
  appId: "1:288293261999:web:97d4eeb10c162e1fe37a5a",
  measurementId: "G-853G7DF1E1"
};

// Initialize Firebase App and Auth
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app };
